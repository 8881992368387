@import '../../../node_modules/bootstrap/';

html,
body {
  margin: 0;
  overflow-x: hidden;
  font-family: "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: left;
  background-color: #f5f7fb;

}

.App {
  position: relative;
  min-height: 100vh;
}

#page-wrap {
  /* text-align: center; */
  overflow-x: hidden;
  padding-bottom: 60px;

}

.page {
  margin: 15px;
}

/* *,
*:before,
*:after {
  box-sizing: inherit;
} */

.margin10Bottom {
  margin-bottom: 10px;
}

.h4 {
  font-family: inherit;
  color: inherit;
  font-weight: inherit;
  font-size: 20px;

}

.text-center {
  text-align: center;
}

.reqForm {
  width: 60%;
  text-align: center;
  margin: 0 auto;
  background-color: white;
  padding: 2rem;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.25);
  border-top: 8px solid var(--abi-yellow);
  border-bottom: 8px solid var(--abi-yellow);
}

.fresh-form {
  text-align: center;
  width: 100%;
  margin: 0 auto;
  margin-top: 30px;
  background-color: white;
  padding: 2rem;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.25);
  /* border-top: 8px solid steelblue; */
  border-top: 8px solid var(--abi-yellow);
  border-bottom: 8px solid var(--abi-yellow);

}

.fresh-label {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 0rem;
  font-size: 13px;
  color: rgb(71, 70, 70);
  font-weight: 500;

}

.fresh-title {
  margin-bottom: 0.5rem;
}

#text-action {
  float: right;
}

.fresh-field-wrapper {
  margin-bottom: 1.5rem;
  color: inherit;
  width: calc(100% / 2 - 20px);
}


.fresh-input {
  width: 100%;
  background-color: rgb(243, 239, 239);
  border: 0;
  padding: 1rem;
  font-weight: inherit;
}

.fresh-input-textarea {
  min-height: 150px;
}

.label-body {
  margin-bottom: 0rem;
  font-size: 12px;
  color: rgb(71, 70, 70);
}

.fresh-button {
  border: 0;
  background-color: var(--abi-yellow);
  color: var(--abi-black);
  display: inline-block;
  cursor: pointer;
  font-size: 18px;
  font-weight: bold;
  padding: 10px 20px;
  margin-right: 1rem;
  text-decoration: none;
}

.dashboard-button {
  border: 0;
  background-color: var(--abi-yellow);
  display: inline-block;
  cursor: pointer;
  color: #ffffff;
  padding: 10px 20px;
  text-decoration: none;
}

.navbar-custom {
  background-color: var(--abi-yellow);
}

.Dashbored_div {
  width: 95%;
  margin-left: 2.5%;
  margin-top: 30px;
  background-color: white;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.25);
  border-top: 8px solid var(--abi-yellow);
  font-size: small;
}

.table-mini {
  /* background-color: white;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.25);
  font-size: small; */
  width: 100%;
}

.UploaderNreview {
  margin-top: 6rem;
  float: right;
  position: relative;
}

.Download_btn {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 800px;
  margin: auto;
}

.cust_header-class {
  background-color: var(--abi-yellow);
  vertical-align: top;
  color: #EBF1FF;
}

.div_approve_reject {
  margin: 5px;
  position: relative;
  display: inline-block;
}

.div_approve_reject_wrapper {
  display: flex;
  float: right;
  margin: 2px;
  padding: 2px;
}

.hide_data {
  display: none;
}

.table-scroll {
  overflow: scroll;
  max-height: 600px;
}

.header-sticky {
  position: sticky;
  top: 0;
}

.reminder {
  color: var(--abi-yellow);
  text-align: left;
  font-weight: bold;
  font-size: 18px;
}

.reminder-2 {
  color: var(--abi-yellow);
  text-align: left;
  font-size: 13px;
}

.div_export {
  margin: 15px;
  float: left;
}

.submit {
  display: inline-block;
}

.rejection_reason {
  width: 100%;
  background-color: rgb(243, 239, 239);
  border: 0;
  padding: 1rem;
  font-weight: bold;
  display: inline-block;
}

.advanced-Search {
  margin: 5px;
  padding: 0 10px;
  float: left;
}

.react-bootstrap-table-page-btns-ul {
  float: right;
  margin-top: 0px !important;
}

.line {
  margin-bottom: 18px;
}

.rejectionRes {
  margin-bottom: 1.5rem;

}

.bm-item {
  display: inline-block;

  /* Our sidebar item styling */
  text-decoration: none;
  margin-bottom: 10px;
  color: #d1d1d1;
  transition: color 0.2s;
}

.bm-item:hover {
  color: white;
}

.bm-item:focus {
  outline: none;
}

.bm-burger-button {
  position: absolute;
  width: 30px;
  height: 18px;
  left: 36px;
  top: 66px;
}

.bm-morph-shape {
  fill: #373a47;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #373a47;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #888;
}

/* General sidebar styles */
.bm-menu {
  background: var(--abi-yellow);
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

.bm-menu a {
  color: #b8b7ad;
}

.bm-menu a:hover,
a:focus {
  color: var(--abi-yellow);
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
}

.bm-item-list a {
  padding: 0.8em;
}

.bm-item-list a span {
  margin-left: 10px;
  font-weight: 700;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

#side-bar h2 {
  margin: 0 auto;
  padding: 2em 1em;
  color: rgba(0, 0, 0, 0.4);
  box-shadow: inset 0 -1px rgba(0, 0, 0, 0.2);
}

#side-bar h2 i {
  margin-left: 0.2em;
}

#side-bar h2 span {
  font-size: 1.6em;
  font-weight: 700;
}

#side-bar a {
  padding: 1em;
  text-transform: uppercase;
  transition: background 0.3s, box-shadow 0.3s;
  box-shadow: inset 0 -1px rgba(0, 0, 0, 0.2);
}

#side-bar a span {
  letter-spacing: 1px;
  font-weight: 400;
}

#side-bar a:hover,
#side-bar a:focus {
  background: rgba(0, 0, 0, 0.2);
  box-shadow: inset 0 -1px rgba(0, 0, 0, 0);
  color: #b8b7ad;
}

.select-action {
  width: 35%;
  margin: 1px auto;
  margin-bottom: 3px;
  background-color: white;
  padding: 0.5rem;
  padding-bottom: 6rem;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.25);
  border-top: 8px solid var(--abi-yellow);
  border-bottom: 8px solid var(--abi-yellow);
}

.fresh-field-wrapper_select_role {
  width: 35%;
  margin: 1px auto;
  background-color: white;
  padding: 0.5rem;
  padding-bottom: 6rem;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.25);
  border-top: 8px solid var(--abi-yellow);
  border-bottom: 8px solid var(--abi-yellow);
  color: inherit;
}

.logo {
  /* float: left; */
  background-color: white;
}

.navbar-custom {
  background-color: white;
}

.abc {
  position: relative;
  margin-right: 5px;
}

.abc::before {
  content: "";
  left: 0;
  right: 0;
  bottom: 0;
  height: 2px;
  background-color: white;
  position: absolute;
  transform: scaleX(0);
  transition: transform 300ms ease-in-out;
}

.abc-2 {
  position: relative;
  margin-right: 5px;
}

.abc-2::before {
  content: "";
  left: 0;
  right: 0;
  bottom: 0;
  height: 2px;
  background-color: white;
  position: absolute;
}

.abc:hover::before {
  transform: scaleX(1);
}

.userdetails {
  float: left;
  padding-top: 10px;
}

.svg {
  float: left;
  padding-left: 5px;
}

.username {
  width: 80%;
  color: white;
}

.color-nav {
  background-color: var(--abi-yellow);
}

.linkText {
  font-weight: 500;
  font-size: 15px;
  text-decoration: none !important;
  margin-right: 20px;
  color: black;
}

.linkText:hover {
  text-decoration: none;
  color:white !important
}

.admin-panel {
  margin: 15px;
}

.container-body {
  font-size: 10px;
}

/* .footer {
  background: #fff;
  background-size: 100% 60px;
  border-top: 1px solid rgba(0, 40, 100, 0.12);
  background-image: url(./footer_wave.svg);
  background-repeat: no-repeat;
  font-size: 0.875rem;
  padding: 1.25rem 0 0 0;
  color: #9aa0ac;
  bottom: 0px;
  height: 60px;
  width: 100%;
  position: absolute;
} */

.form-header {
  font-size: 25px;
  font-weight: 500;
  position: relative;
  padding-bottom: 15px;
}

.radio-inline {
  width: calc(100% / 2)
}

.fresh-form .userDetails {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.reqForm .userDetails {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}



.userDetails .fresh-field-wrapper input {
  /* height: 30px; */
  width: 100%;
}

#note {
  color: var(--abi-yellow);
}


@media only screen and (max-width: 810px) {
  .Download_btn {
    justify-content: space-around;
  }

  .fresh-field-wrapper {
    width: 100%;
  }

  .fresh-form {
    width: 100%;
  }

  .reqForm {
    width: 100%;
  }

  .select-action {
    width: 90%
  }

  .fresh-field-wrapper_select_role {
    width: 90%;
  }
}

@media only screen and (max-width: 470px) {
  .navbar-2 {
    justify-content: space-around;
  }

  .svg {
    display: none;
  }

}

.dllink {
  color: var(--abi-yellow);
  cursor: pointer;
}

.fresh-button:hover,
.fresh-button:visited {
  color: #fff;
}

.react-bootstrap-table-pagination {
  text-align: left;
}

.wide-page {
  width: 95%;
  margin-left: 2.5%;
}

.wide-page .fresh-form,
.reqForm,
.wide-page .Download_btn {
  max-width: 100%;
}

.batches-table {
  text-align: left;
}

.batches-table tr {
  cursor: pointer;
}

.batch-data-table {
  text-align: left;
}

.btn-icon {
  padding: 2px 5px;
}