input,
textarea {
    padding: 8px 8px;
    box-sizing: border-box;
    border: none;
    outline: none;
    width: 100%;
    border-radius: 5px;
}

.form_card {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 50vw;
}

#form {
    background: linear-gradient(90deg, rgb(227, 175, 50) 0%, rgb(244, 224, 15) 100%);
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    padding: 12px 0;
    margin-top: 20px;

}

.form_label {
    text-align: left !important;
    margin: 0;
    padding: 0;
}

.signup_box {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    /* height: 90vh; */

}