#portals_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 95vh;
    overflow: hidden;
}

.portals_card_parent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    position: relative;
}


.portals_card {
    /* background: gray; */
    width: 100%;
    height: 200px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    flex-wrap: wrap;
}

.portal_img {
    display: block;
    margin: 0 auto;
    width: 70px;
    height: 70px;
}


.portals_card_parent::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 4px;
    background-color: var(--abi-yellow);
    transform: scaleX(0);
    transform-origin: bottom right;
    transition: transform 0.3s ease;
}


.portals_card_parent:hover::after {
    transform: scaleX(1);
    transform-origin: bottom left;
}