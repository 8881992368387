
html,

body {

    margin: 0;

    overflow-x: hidden;

    font-family: "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, sans-serif;

    font-size: 12px;

    font-weight: 400;

    line-height: 1.5;

    color: #495057;

    text-align: left;

    background-color: #f5f7fb;

}
 
.App {

    position: relative;

    min-height: 100vh;

}

.table-bordered thead th,

.table-bordered thead td{

    color: black !important;

}

#page-wrap {

    text-align: center;

    overflow-x: hidden;

    padding-bottom: 60px;

}
 
.textAlignCenter {

    text-align: center;

}
 
.h4 {

    color: black !important;

    font-weight: inherit;

    font-size: 20px;

}
 
.fresh-form {

    width: 500px;

    margin: auto;

    margin-top: 20px;

    background-color: white;

    padding: 2rem;

    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.25);

    /* border-top: 8px solid steelblue; */

    border-top: 8px solid #f1c40f;

    border-bottom: 8px solid #f1c40f;

}
 
.fresh-label {

    display: flex;

    flex-direction: column;

    align-items: flex-start;

    margin-bottom: 0rem;

    font-size: 11px;

    color: rgb(71, 70, 70);

}
 
.fresh-title {

    margin-bottom: 0.5rem;

}
 
#text-action {

    float: right;

}
 
.fresh-field-wrapper {

    margin-bottom: 1.5rem;

    color: inherit;

}
 
.select-items {

    position: absolute;

    background-color: grey;

    top: 100%;

    left: 0;

    right: 0;

    z-index: 99;

}
 
.fresh-input {

    width: 100%;

    background-color: rgb(243, 239, 239);

    border: 0;

    padding: 1rem;

    font-weight: inherit;

    cursor: auto;

    background-color: hsl(0, 0%, 100%);

    border-color: hsl(0, 0%, 80%);

    border-radius: 4px;

    border-style: solid;

    border-width: 1px;

}
 
.fresh-input-textarea {

    min-height: 150px;

}
 
.label-body {

    margin-right: 50px;

    margin-bottom: 0rem;

    font-size: 11px;

    color: rgb(71, 70, 70);

}
 
.fresh-button {

    border: 0;

    background-color: #f1c40f;

    display: inline-block;

    cursor: pointer;

    color: black;

    font-size: 15px;

    padding: 10px 20px;

    margin-right: 1rem;

    text-decoration: none;

}

/* christmas navbar background */

.navbar-custom {

    /* background-color: black !important; */

    background: url('./ChristmasGif.gif');

    background-position: center;

    background-size: cover;

}
 
.UploaderNreview {

    margin-top: 6rem;

    float: right;

    position: relative;

}
 
.Download_btn {

    /* margin-top: 2rem; */

    float: right;

    position: relative;

    max-width: 20%;

}
 
.cust_header-class {

    background-color: #f1c40f;

    vertical-align: top;

    color: #EBF1FF;

}
 
.div_approve_reject {

    margin: 5px;

    float: right;

    position: relative;

}
 
.cust_btn-cls {

    margin: 5px;

}
 
.hide_data {

    display: none;

}
 
.reminder {

    color: red;

    text-align: left;

    font-weight: bold;

}
 
.filter_style {

    font-size: 10px;

    /* background-color: aqua; */

    /* width:auto; */

}
 
.Dashbored_div {

    width: 98%;

    margin: 2rem;

    background-color: white;

    /* padding: 2rem; */

    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.25);

    border-top: 8px solid #f1c40f;

    font-size: 8px;

}
 
/* .scrollme {

  overflow-x: auto;

  overflow-y: auto;

} */
 
.report-form {

    font-family: Arial;

    margin: 0px;

    background-color: white;

    margin: 0 auto;

    padding: 1.5rem;

    border-top: 5px solid var(--abi-yellow);

    border-bottom: 5px solid var(--abi-yellow);

    box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;

    border-radius: 5px;

}
 
.parameters {

    width: 25%;

    float: left;

}
 
.report-label {

    color: #404040;

    float: left;

    font-size: 15px;

    line-height: 10px;

    padding-top: 6px;

    text-align: right;

}
 
.react-select {

    width: 400px;

}
 
.report_parameter-wrapper {

    margin-bottom: .25rem;

    width: 90%

}
 
.report-input {

    width: 100%;

    background-color: rgb(243, 239, 239);

    border: 1px solid #CCCCCC;

    display: inline-block;

    padding: 1rem;

    font-size: 10px;

    height: 18px;

}
 
.Dashboard_report {

    font-family: Arial;

    width: 75%;

    margin: auto;

    background-color: white;

    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.25);

    border-top: 3px solid var(--abi-yellow);

    font-size: small;

    /* float: right; */

    table-layout: auto;

    word-wrap: break-word;

    overflow-x: hidden;

}
 
.line {

    margin-bottom: 18px;

}
 
.bm-item {

    display: inline-block;

    /* Our sidebar item styling */

    text-decoration: none;

    margin-bottom: 10px;

    color: #d1d1d1;

    transition: color 0.2s;

}
 
.bm-item:hover {

    color: white;

}
 
.bm-item:focus {

    outline: none;

}
 
.bm-burger-button {

    position: absolute;

    width: 30px;

    height: 18px;

    left: 36px;

    top: 66px;

}
 
.bm-morph-shape {

    fill: #373a47;

}
 
/* Color/shape of burger icon bars */
 
.bm-burger-bars {

    background: #373a47;

}
 
/* Position and sizing of clickable cross button */
 
.bm-cross-button {

    height: 24px;

    width: 24px;

}
 
/* Color/shape of close button cross */
 
.bm-cross {

    background: #888;

}
 
/* General sidebar styles */
 
.bm-menu {

    background: #f1c40f;

    padding: 2.5em 1.5em 0;

    font-size: 1.15em;

}
 
.bm-menu a {

    color: #b8b7ad;

}
 
.bm-menu a:hover,

a:focus {

    color: #f1c40f;

}
 
/* Morph shape necessary with bubble or elastic */
 
.bm-morph-shape {

    fill: #373a47;

}
 
/* Wrapper for item list */
 
.bm-item-list {

    color: #b8b7ad;

}
 
.bm-item-list a {

    padding: 0.8em;

}
 
.bm-item-list a span {

    margin-left: 10px;

    font-weight: 700;

}
 
/* Styling of overlay */
 
.bm-overlay {

    background: rgba(0, 0, 0, 0.3);

}
 
#side-bar h2 {

    margin: 0 auto;

    padding: 2em 1em;

    color: rgba(0, 0, 0, 0.4);

    box-shadow: inset 0 -1px rgba(0, 0, 0, 0.2);

}
 
#side-bar h2 i {

    margin-left: 0.2em;

}
 
#side-bar h2 span {

    font-size: 1.6em;

    font-weight: 700;

}
 
#side-bar a {

    padding: 1em;

    text-transform: uppercase;

    background: 0.3s, box-shadow 0.3s;

    box-shadow: inset 0 -1px rgba(0, 0, 0, 0.2);

}
 
#side-bar a span {

    letter-spacing: 1px;

    font-weight: 400;

}
 
#side-bar a:hover,

#side-bar a:focus {

    background: rgba(0, 0, 0, 0.2);

    box-shadow: inset 0 -1px rgba(0, 0, 0, 0);

    color: #b8b7ad;

}
 
.select-action {

    /* width: 500px; */

    width: 35%;

    margin: 1px auto;

    margin-bottom: 3px;

    background-color: white;

    padding: 0.5rem;

    padding-bottom: 6rem;

    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.25);

    border-top: 8px solid #f1c40f;

    border-bottom: 8px solid #f1c40f;

}
 
.fresh-field-wrapper_select_role {

    width: 35%;

    margin: 1px auto;

    background-color: white;

    padding: 0.5rem;

    padding-bottom: 6rem;

    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.25);

    border-top: 8px solid #f1c40f;

    border-bottom: 8px solid #f1c40f;

    color: inherit;

}
 
.logo {

    width: 14%;

    float: left;

    /* background-color: white; */

}
 
.navbar-custom {

    background-color: white;

}
 
.userprofile {

    width: 20%;

}
 
.username {

    width: 80%;

    color: white;

}
 
.bm-menu a:hover,

a:focus {

    color: #f1c40f;

}
 
#side-bar h2 {

    margin: 0 auto;

    padding: 2em 1em;

    color: rgba(0, 0, 0, 0.4);

    box-shadow: inset 0 -1px rgba(0, 0, 0, 0.2);

}
 
#side-bar h2 i {

    margin-left: 0.2em;

}
 
#side-bar h2 span {

    font-size: 1.6em;

    font-weight: 700;

}
 
#side-bar a {

    padding: 1em;

    text-transform: uppercase;

    background: 0.3s, box-shadow 0.3s;

    box-shadow: inset 0 -1px rgba(0, 0, 0, 0.2);

}
 
#side-bar a span {

    letter-spacing: 1px;

    font-weight: 400;

}
 
#side-bar a:hover,

#side-bar a:focus {

    background: rgba(0, 0, 0, 0.2);

    box-shadow: inset 0 -1px rgba(0, 0, 0, 0);

    color: #b8b7ad;

}
 
.custom-comparator-class {

    display: none;

    visibility: hidden;

}
 
.forgot-password {

    text-align: right;

    float: right;

    font-size: 10px;

    color: '#039BE5';

}
 
.div_export {

    margin: 10px;

    float: right;

}
 
.class-headers {

    width: 110px;

    /* background-color: aqua; */

}
 
.classheaderdate {

    width: 120px;

}
 
.classheaderid {

    width: 40px;

}
 
.custom-date-class {

    width: 30px;

}
 
.SampleContainer {

    width: 100%;

    display: flex;

    justify-content: center;

}
 
.SampleBox {

    border: 1px solid black;

    width: 20em;

    margin: 2em;

    padding: 10px;

    border-radius: 10px;

}
 
.SampleHeader {

    font-size: 1.25em;

}
 
.Button {

    background-color: #00a1f1;

    border: none;

    color: white;

    display: inline-block;

    padding: 15px;

    font-size: 15px;

    cursor: pointer;

}
 
.Button:disabled {

    background-color: grey;

}
 
.homeButton {

    background: 'mediumpurple';

    padding: '1em';

    margin: '1em';

}
 
;
 
.footers {

    /* background: #fff; */

    background-size: 100% 60px;

    border-top: 1px solid rgba(0, 40, 100, 0.12);

    background-image: url('./footer_wave.svg');

    background-repeat: no-repeat;

    font-size: 0.875rem;

    padding: 1.25rem 0 0 0;

    color: #9aa0ac;

    bottom: 0px;

    height: 60px;

    width: 100%;

    position: absolute;

}
 
.userprofile {

    width: 20%;

}
 
.userdetails {

    float: left;

    padding-top: 10px;

}
 
.svg {

    float: left;

    padding-left: 5px;

    padding-top: 15px;

}
 
.username {

    width: 80%;

    color: white !important;

}
 
.color-nav {

    background: linear-gradient(90deg, rgb(227, 175, 50) 0%, rgb(244, 224, 15) 100%);

}
 
.linkText {

    color: black !important;

}
 
a .nav-link {

    color: black !important;

}
 
.msg-wrapper {

    white-space: pre-wrap;

    background-color: #00a1f1;

}
 
.margin10Bottom {

    margin-bottom: 10px;

}
 
@media screen and (max-width: 600px) {

    .fresh-form {

        width: 90%;

        margin: auto;

        margin-top: 20px;

        background-color: white;

        padding: 2rem;

        box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.25);

        /* border-top: 8px solid steelblue; */

        border-top: 8px solid #f1c40f;

        border-bottom: 8px solid #f1c40f;

    }
 
    .userdetails {

        display: none;

    }

}