.popup {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
}
.popup_inner {
  position: absolute;
  left: 20%;
  right: 20%;
  top: 20%;
  bottom: 25%;
  margin: auto;
  border-radius: 20px;
  background: rgb(201, 199, 199);
}
