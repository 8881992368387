.box_wrapper_userRegistration {
    width: 100vw;
    /* background: gray; */
}

.userRegistration_select {
    width: 75% !important
}

.userRegistration_box {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 50%;
    margin: 0 auto;
    padding: 1.5rem;
    border-top: 5px solid var(--abi-yellow);
    border-bottom: 5px solid var(--abi-yellow);
    box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
    border-radius: 5px;
}

.userRegistration_input {
    display: block;
    width: 100%;
    margin: 0 auto;
    outline: none;
}


.input_wrapper_userRegistration {
    /* background: #000; */
    width: 70%;
    margin-bottom: 8px;
}

#userRegistration_info {
    color: rgb(179, 41, 41);
    text-align: justify;
}

.userRegistration_button {
    background: var(--abi-yellow);
    color: black;
    border-radius: 2px;
}

.astrik {
    color: red;
}