.box_wrapper {
    width: 100vw;
    /* background: gray; */
}

.retpack_select {
    width: 75% !important
}

.no-border {
    
    box-shadow: none !important; /* You may want to include this as bootstrap applies these styles too */
}
.retpack_box {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 50%;
    margin: 0 auto;
    padding: 1.5rem;
    border-top: 5px solid var(--abi-yellow);
    border-bottom: 5px solid var(--abi-yellow);
    box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
    border-radius: 5px;
}

.retpack_input {
    display: block;
    width: 100%;
    margin: 0 auto;
    outline: none;
}


.input_wrapper {
    /* background: #000; */
    width: 80%;
    margin-bottom: 8px;
}

#retpack_info {
    color: rgb(179, 41, 41);
    text-align: justify;
}

.retpack_button {
    background: var(--abi-yellow);
    color: black;
    border-radius: 2px;
}

.astrik {
    color: red;
}

#containers_css {
    display: flex;
    width: 100%;
    justify-content: "center";
    align-items: "center";
    flex-direction: "column";
    background: blue;
}