.row-styling{
    background-color: aquamarine;
}
.ticketTable{
    width: 100%;
}
.dzu-previewContainer{
    padding:.5vw !important
}
.chatbotrightbottom{
    position: fixed !important;
    bottom:-7px;
    right:10px;
    z-index: 9999;
}
.dzu-inputLabel, .dzu-inputLabelWithFiles{
    font-size: 1.3vw !important;
    color: #f1c40f !important;
    font-family: Arial, Helvetica, sans-serif;
}
.dzu-dropzone{
    border:1px solid #ced4da !important;
    border-color: rgb(219, 219, 219) !important;
    border-radius: 4px !important;
}
.ellipsis{
    white-space: nowrap; 
    width: 200px; 
    overflow: hidden;
    text-overflow: ellipsis; 
}